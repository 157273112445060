import {reactive, readonly} from "vue";
import TokenService from "./services/token.service";

const state = reactive({
    selectedPeroid: {quarter: 1, year: 2022},
    instancePatch: process.env.VUE_APP_INSTANCE_PATH
});

const setSelectedPeroid = function (selectedPeroid) {
    state.selectedPeroid = selectedPeroid;
};

// const setInstancePatch = function (instancePrefix, instance) {
//     if (state.instancePatch === "") {
//         state.instancePatch = !instancePrefix || !instance ? window.location.pathname : `/${instancePrefix}/${instance}`;
//         state.instancePatch = state.instancePatch === `/` || state.instancePatch === `/login` ? '' : state.instancePatch;
//     }
// };

const getInstancePath = function () {
    return state.instancePatch;
};

const checkPermission = function (permission) {
    // console.log(permission);
    return localStorage.getItem('userPermissions') ? TokenService.isMasterAdmin() ? true : JSON.parse(localStorage.getItem('userPermissions'))
        .includes(permission) : false;
};

export default {state: readonly(state), setSelectedPeroid, checkPermission, getInstancePath};
