import api from './api';

import TokenService from "./token.service";

const API_URL = `/auth/`;

class AuthService {
  login(user) {
    return api
      .post(API_URL + 'login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
          // localStorage.setItem('user', JSON.stringify(response.data));
          // localStorage.setItem('accessToken', response.data.accessToken);
          // axios.defaults.headers.common['Authorization'] = localStorage.getItem('accessToken');
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
    // localStorage.removeItem('user');
  }

  register(user) {
    return api.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
