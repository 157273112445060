<template>
    <div id="app">
        <nav class="navbar navbar-expand navbar-dark bg-dark">
            <a href="/" class="navbar-brand"><img src="@/assets/img/Belka.png" style="height: 35px"/></a>
            <div class="navbar-nav mr-auto">
                <!--                <li class="nav-item">-->
                <!--                    <router-link to="/home" class="nav-link">-->
                <!--                        <font-awesome-icon icon="home"/>-->
                <!--                        Home-->
                <!--                    </router-link>-->
                <!--                </li>-->
                <!--                <li v-if="showAdminBoard" class="nav-item">-->
                <!--                    <router-link to="/admin" class="nav-link">Admin Board</router-link>-->
                <!--                </li>-->
                <!--                <li v-if="showModeratorBoard" class="nav-item">-->
                <!--                    <router-link to="/mod" class="nav-link">Moderator Board</router-link>-->
                <!--                </li>-->
                <!--                <li class="nav-item">-->
                <!--                    <router-link v-if="currentUser" to="/user" class="nav-link">User</router-link>-->
                <!--                </li>-->
            </div>

            <div v-if="!currentUser" class="navbar-nav ml-auto">
                <!--                <li class="nav-item">-->
                <!--                    <router-link to="/register" class="nav-link">-->
                <!--                        <font-awesome-icon icon="user-plus"/>-->
                <!--                        Sign Up-->
                <!--                    </router-link>-->
                <!--                </li>-->
                <li class="nav-item">
                    <router-link to="{{this.instancePath}}/login" class="nav-link">
                        <font-awesome-icon icon="sign-in-alt"/>
                        Zaloguj
                    </router-link>
                </li>
            </div>

            <div v-if="currentUser" class="navbar-nav ml-auto">
                <select v-model="selectedPeroid" @change="onChangeSelectedPeroid(selectedPeroid)"
                        v-permission="'quartets:list'">
                    <option v-for="(option, index) in peroids" :value="option" v-bind:key="index">
                        Rok: {{ option.year }} | Kwartał: {{ option.quarter }}{{option.closed ? ' | Zamknięty' : ''}}
                    </option>
                </select>
                <li class="nav-item">
                    <router-link to="/profile" class="nav-link">
                        <font-awesome-icon icon="user"/>
                        {{ currentUser.username }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click.prevent="logOut">
                        <font-awesome-icon icon="sign-out-alt"/>
                        Wyloguj
                    </a>
                </li>
            </div>
        </nav>
    </div>
    <div class="p-grid">
        <div class="p-col" style="position: absolute">
            <PanelMenu :model="items"
                       v-show="this.$route.fullPath !== `${instancePath}/login` && this.$route.fullPath !== `${instancePath}/register`"></PanelMenu>
        </div>
        <div class="p-col">
            <router-view class="router-view"/>
        </div>
    </div>
    <p-toast></p-toast>
</template>

<script>

    import PeroidsService from './services/peroids.service.js';
    import EventBus from "./common/EventBus";

    export default {
        inject: ['global', 'peroids'],
        data() {
            return {
                selectedPeroid: this.global.state.selectedPeroid,
                instancePath: this.global.state.instancePatch,
                items: [
                    {
                        label: 'Formularze danych',
                        icon: 'pi pi-fw pi-file-excel',
                        visible: () => this.global.checkPermission('declarations:view'),
                        items: [
                            {
                                label: 'Deklaracje',
                                icon: 'pi pi-fw pi-file',
                                to: `${this.global.state.instancePatch}/formularze-danych/deklaracje/lista`,
                                visible: () => this.global.checkPermission('declarations:view')
                            },
                            {
                                label: 'Jednostki oświaty',
                                icon: 'fas fa-school',
                                to: `${this.global.state.instancePatch}/formularze-danych/jednostki-oswiaty`
                            },
                            {
                                label: 'MOPS',
                                icon: 'pi pi-fw pi-home',
                                to: `${this.global.state.instancePatch}/formularze-danych/mops`
                            },
                            {
                                label: 'Dodatek mieszkaniowy',
                                icon: 'fa fa-house-user',
                                to: `${this.global.state.instancePatch}/formularze-danych/dodatek-mieszkaniowy`
                            },
                            {
                                // <font-awesome-icon icon="home"/>
                                label: 'Zużycie wody',
                                icon: 'fa fa-tint',
                                to: `${this.global.state.instancePatch}/formularze-danych/zuzycie-wody`
                            },
                            {
                                label: 'Jednostki samorządowe',
                                icon: 'fa fa-hotel',
                                to: `${this.global.state.instancePatch}/formularze-danych/jednostka-samorzadowa`
                            },
                            {
                                label: 'Ewidencja ludności',
                                icon: 'fa fa-users',
                                to: `${this.global.state.instancePatch}/formularze-danych/ewidencja-ludnosci`
                            }
                        ]
                    },
                    {
                        label: 'Raporty',
                        icon: 'pi pi-fw pi-folder-open',
                        visible: () => this.global.checkPermission('reports:view'),
                        items: [
                            {
                                label: 'Dodaj raport',
                                icon: 'pi pi-plus-circle',
                                to: `${this.global.state.instancePatch}/raporty/dodaj`

                            },
                            {
                                label: 'Lista raportów',
                                icon: 'pi pi-list',
                                to: `${this.global.state.instancePatch}/raporty/deklaracje/lista`
                            },
                            {
                                label: 'Lista raportów woda',
                                icon: 'pi pi-list',
                                to: `${this.global.state.instancePatch}/raporty/woda/lista`
                            }
                        ]
                    },
                    {
                        label: 'Okresy',
                        icon: 'pi pi-fw pi-calendar-times',
                        visible: () => this.global.checkPermission('quartets:list'),
                        items: [
                            {
                                label: 'Wszystkie okresy',
                                icon: 'pi pi-fw pi-external-link',
                                to: `${this.global.state.instancePatch}/okresy`
                            }
                        ]
                    },
                    {
                        label: 'Słownik',
                        icon: 'pi pi-fw pi-book',
                        visible: () => this.global.checkPermission('units:view'),
                        items: [
                            {
                                label: 'Lista jednostek',
                                icon: 'pi pi-fw pi-list',
                                to: `${this.global.state.instancePatch}/jednostki/lista`,
                                visible: () => this.global.checkPermission('units:view'),
                            },
                        ]
                    },
                    {
                        label: 'Użytkownicy',
                        icon: 'pi pi-fw pi-user',
                        visible: () => this.global.checkPermission('users:view'),
                        items: [
                            {
                                label: 'Dodaj użytkownika',
                                icon: 'pi pi-fw pi-user-plus',
                                to: `${this.global.state.instancePatch}/uzytkownicy/dodaj`

                            },
                            {
                                label: 'Wszyscy użytkownicy',
                                icon: 'pi pi-fw pi-users',
                                to: `${this.global.getInstancePath()}/uzytkownicy/lista`
                            }
                        ]
                    },
                    {
                        label: 'Opcje',
                        icon: 'pi pi-fw pi-calendar',
                        visible: () => this.global.checkPermission('roles:view'),
                        items: [
                            {
                                label: 'Zarządzanie rolami',
                                icon: 'pi pi-fw pi-users',
                                to: `${this.global.state.instancePatch}/role/lista`,
                                visible: () => this.global.checkPermission('roles:view'),
                            },
                            {
                                label: 'Parametry',
                                icon: 'pi pi-fw pi-users',
                                to: `${this.global.state.instancePatch}/parametry/lista`,
                            }
                        ]
                    }
                ]
            }
        },
        // eslint-disable-next-line no-dupe-keys
        // created() {
            // this.global.setInstancePatch(this.$route.params.instancePrefix, this.$route.params.instance);
        // },
        computed: {
            currentUser() {
                // this.global.setInstancePatch(this.$route.params.instancePrefix, this.$route.params.instance);
                return this.$store.state.auth.user;
            },
            showAdminBoard() {
                if (this.currentUser && this.currentUser['roles']) {
                    return this.currentUser['roles'].includes('ROLE_ADMIN');
                }

                return false;
            },
            showModeratorBoard() {
                if (this.currentUser && this.currentUser['roles']) {
                    return this.currentUser['roles'].includes('ROLE_MODERATOR');
                }

                return false;
            }
        },
        methods: {
            onChangeSelectedPeroid(selectedPeroid) {
                this.global.setSelectedPeroid(selectedPeroid);
            },
            logOut() {
                this.$store.dispatch('auth/logout');
                this.$router.push(`${this.global.state.instancePatch}/login`);
            },
            checkPeroidsList() {
                PeroidsService.getClosedQuarters().then(
                    (response) => {
                        const closedQuarters = response.data;
                        for (const i in this.peroids) {// :TO DO refactor
                            for (const j in closedQuarters) {
                                if (this.peroids[i].year === closedQuarters[j].year && this.peroids[i].quarter === closedQuarters[j].quarter
                                    && (new Date().getFullYear() > new Date(closedQuarters[j].validTo).getFullYear() ||
                                        (new Date().getFullYear() === new Date(closedQuarters[j].validTo).getFullYear() && new Date().getMonth() >= new Date(closedQuarters[j].validTo).getMonth())
                                    )
                                ) {
                                    this.peroids[i].id = closedQuarters[j].id;
                                    this.peroids[i].closed = true;
                                }
                            }
                        }
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        },
        mounted() {
            this.checkPeroidsList();
            EventBus.on("logout", () => {
                this.logOut();
            });
        },
        beforeUnmount() {
            EventBus.remove("logout");
        }
    };
</script>
