import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuex from 'vuex';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/main.css';
import {FontAwesomeIcon} from './plugins/font-awesome'
import {faTint, faHouseUser, faSchool, faHotel, faAlignLeft, faUsers} from '@fortawesome/free-solid-svg-icons'
import {dom, library} from '@fortawesome/fontawesome-svg-core'
import PrimeVue from 'primevue/config';
import PanelMenu from 'primevue/panelmenu';
import TieredMenu from 'primevue/tieredmenu';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import FileUpload from 'primevue/fileupload';
import TreeTable from 'primevue/treetable';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import global from "./global";

import setupInterceptors from './services/setupInterceptors';
import TokenService from "./services/token.service";

setupInterceptors(store);

const unitTypes = [
    {
        name: "deklaracje",
        unitTypeKey: 'all'
    },
    {
        name: "jednostki-oswiaty",
        unitTypeKey: 'education'
    },
    {
        name: "mops",
        unitTypeKey: 'social'
    },
    {
        name: "dodatek-mieszkaniowy",
        unitTypeKey: 'housing'
    },
    {
        name: "zuzycie-wody",
        unitTypeKey: 'water'
    },
    {
        name: "jednostka-samorzadowa",
        unitTypeKey: 'government'
    },
    {
        name: "ewidencja-ludnosci",
        unitTypeKey: 'population'
    }
];

let peroids = [
    {
        quarter: 1,
        year: 2021,
    },
    {
        quarter: 2,
        year: 2021,
    },
    {
        quarter: 3,
        year: 2021,
    },
    {
        quarter: 4,
        year: 2021,
    },
    {
        quarter: 1,
        year: 2022,
    },
    {
        quarter: 2,
        year: 2022,
    },
    {
        quarter: 3,
        year: 2022,
    },
    {
        quarter: 4,
        year: 2022,
    }
];

library.add(
    faTint,
    faHouseUser,
    faSchool,
    faHotel,
    faAlignLeft,
    faUsers
);
dom.watch();//FontAwesomeIcon
const userPermissionsDirective = {
    created: function (el, binding, vnode) {
        if (!TokenService.isMasterAdmin() && !localStorage.getItem('userPermissions')
            .includes(binding.value)) {
            vnode.el.style.display = "none";
        }
    }
};

createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .use(ToastService)
    .use(Vuex)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component('PanelMenu', PanelMenu)
    .component('TieredMenu', TieredMenu)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('InputNumber', InputNumber)
    .component('Dropdown', Dropdown)
    .component('MultiSelect', MultiSelect)
    .component('p-toast', Toast)
    .component('FileUpload', FileUpload)
    .component('TreeTable', TreeTable)
    .component('Dialog', Dialog)
    .component('RadioButton', RadioButton)
    .provide('unitTypes', unitTypes)
    .provide('peroids', peroids)
    .provide('global', global)
    .directive('permission', userPermissionsDirective)
    .mount("#app");
