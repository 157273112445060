<template>
    <img src="@/assets/img/System_e-odpady.png"
         style="width: auto; max-width: calc(100% - 230px); display: block; margin-left: 226px;"/>
</template>

<script>

    import TokenService from "../services/token.service";

    export default {
        inject: ['global'],
        name: "Home",
        beforeMount() {
            console.log(TokenService.getParseLocalAccessToken());
            if(TokenService.getParseLocalAccessToken().action === "change-password"){
                this.$router.push({path: `${this.global.state.instancePatch}/change-password`});
            }
        }
    };
</script>
