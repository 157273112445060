class TokenService {
     parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.accessToken;
    }

    getParseLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return this.parseJwt(user?.accessToken);
    }

    isMasterAdmin() {
        const user = JSON.parse(localStorage.getItem("user"));
        return this.parseJwt(user?.accessToken).isAdmin;
    }

    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.accessToken = `Bearer ${token}`;
        localStorage.setItem("userPermissions", JSON.stringify(this.parseJwt(user.accessToken).scope));
        localStorage.setItem("user", JSON.stringify(user));
    }

    setUser(user) {
        user.accessToken = `Bearer ${user.accessToken}`;
        localStorage.setItem("userPermissions", JSON.stringify(this.parseJwt(user.accessToken).scope));
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem("user");
        localStorage.removeItem("userPermissions");
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    getUserPermissions() {
        return JSON.parse(localStorage.getItem("userPermissions"));
    }
}

export default new TokenService();
