import api from './api';

const API_URL = `/quarters`;

class PeroidsService {
    getClosedQuarters() {
        return api.get(API_URL
        );
    }

    closeQuarter(content) {
        return api.post(`${API_URL}`, content);
    }


    openQuarter(id) {
        return api.patch(`${API_URL}/${id}`,  {validTo: new Date(new Date().setMonth(new Date().getMonth()+1))}
        );
    }

}

export default new PeroidsService();
