import {createWebHistory, createRouter} from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const DataForms = () => import("./components/data-forms/DataForms.vue");
const SelectUnit = () => import("./components/data-forms/SelectUnit.vue");

const Form = () => import("./components/data-forms/form/Form.vue");
const FormList = () => import("./components/data-forms/form/FormList.vue");
const AddEditForm = () => import("./components/data-forms/form/AddEditForm.vue");

const Declaration = () => import("./components/data-forms/deklarations/Declaration");
const DeclarationList = () => import("./components/data-forms/deklarations/DeclarationList");
const AddEditDeclaration = () => import("./components/data-forms/deklarations/AddEditDeclaration");

const Profile = () => import("./components/Profile.vue");
const BoardAdmin = () => import("./components/users/UsersList.vue");
const BoardModerator = () => import("./components/BoardModerator.vue");
const BoardUser = () => import("./components/BoardUser.vue");

const Users = () => import("./components/users/Users.vue");
const UsersList = () => import("./components/users/UsersList.vue");
const AddEditUser = () => import("./components/users/AddEditUser.vue");
const ChangePassword = () => import("./components/users/ChangePassword.vue");

const Units = () => import("./components/units/Units.vue");
const UnitsList = () => import("./components/units/UnitsList.vue");
const AddEditUnit = () => import("./components/units/AddEditUnit.vue");

const PeroidsList = () => import("./components/peroids/PeroidsList");

const Reports = () => import("./components/reports/Reports.vue");
const ReportsList = () => import("./components/reports/ReportsList.vue");
const AddReport = () => import("./components/reports/AddReport.vue");
const ReportDetailsList = () => import("./components/reports/ReportDetailsList.vue");
const ReportsDetailsShow = () => import("./components/reports/ReportsDetailsShow.vue");

const Permissions = () => import("./components/permissions/Permissions.vue");
const PermissionsList = () => import("./components/permissions/PermissionsList.vue");

const Roles = () => import("./components/roles/Roles.vue");
const RolesList = () => import("./components/roles/RolesList.vue");
const AddEditRole = () => import("./components/roles/AddEditRole.vue");

const Parameters = () => import("./components/parameters/Parameters");
const ParametersList = () => import("./components/parameters/ParametersList");
const EditParameter = () => import("./components/parameters/EditParameter");

const AppWrapper = () => import("./components/AppWrapper.vue");

import Global from "./global.js";


const routes = [
    {
        path: "", ///:instancePrefix/:instance
        // alias: '/',
        component: AppWrapper,
        children: [
            {
                path: "",
                name: "home",
                component: Home,
            },
            {
                path: "home",
                component: Home,
            },
            {
                path: 'formularze-danych',
                name: 'DataForms',
                component: DataForms,
                children: [
                    {
                        path: 'deklaracje',
                        name: 'Declration',
                        component: Declaration,
                        children: [
                            {
                                path: "lista",
                                name: 'DeclarationList',
                                component: DeclarationList,
                            },
                            {
                                path: 'dodaj',
                                name: 'AddDeclration',
                                component: AddEditDeclaration,
                            },
                            {
                                path: 'edytuj/:declarationId',
                                name: 'EditDeclration',
                                component: AddEditDeclaration,
                            }]
                    },
                    {
                        path: ':type',
                        name: 'SelectUnit',
                        component: SelectUnit
                    },
                    {
                        path: ':type/:unitId',
                        name: 'Form',
                        component: Form,
                        children: [
                            {
                                path: "lista",
                                name: 'FormList',
                                component: FormList,
                            },
                            {
                                path: 'dodaj',
                                name: 'AddForm',
                                component: AddEditForm,
                            },
                            {
                                path: 'edytuj/:formId',
                                name: 'EditForm',
                                component: AddEditForm,
                            }]
                    }
                ]
            },
            {
                path: "login",
                component: Login,
            },
            {
                path: "register",
                component: Register,
            },
            {
                path: 'jednostki',
                name: 'UnitsList',
                component: Units,
                children: [
                    {
                        path: 'lista',
                        name: 'UnitsList',
                        component: UnitsList
                    },
                    {
                        path: 'dodaj',
                        name: 'AddUnit',
                        component: AddEditUnit
                    },
                    {
                        path: 'edytuj/:unitId',
                        name: 'EditUnit',
                        component: AddEditUnit,
                    }
                ]
            },
            {
                path: 'uzytkownicy',
                name: 'UsersList',
                component: Users,
                children: [
                    {
                        path: 'lista',
                        name: 'UsersList',
                        component: UsersList
                    },
                    {
                        path: 'dodaj',
                        name: 'AddUser',
                        component: AddEditUser
                    },
                    {
                        path: 'edytuj/:userId',
                        name: 'EditUser',
                        component: AddEditUser,
                    }
                ]
            },
            {
                path: 'raporty',
                name: 'Reports',
                component: Reports,
                children: [
                    {
                        path: 'dodaj',
                        name: 'AddReport',
                        component: AddReport
                    },
                    {
                        path: ':type/lista',
                        name: 'ReportsList',
                        component: ReportsList
                    },
                    {
                        path: ':type/lista/:reportId/lista',
                        name: 'ReportDetailsList',
                        component: ReportDetailsList
                    },
                    {
                        path: ':type/lista/:reportId/lista/:showId',
                        name: 'ReportsDetailsShow',
                        component: ReportsDetailsShow,
                    }
                ]
            },
            {
                path: "okresy",
                name: "PeroidsList",
                component: PeroidsList,
            },
            {
                path: 'uprawnienia',
                name: 'Permissions',
                component: Permissions,
                children: [
                    {
                        path: 'lista',
                        name: 'PermissionsList',
                        component: PermissionsList
                    }
                ]
            },
            {
                path: 'role',
                name: 'Roles',
                component: Roles,
                children: [
                    {
                        path: 'lista',
                        name: 'RolesList',
                        component: RolesList
                    },
                    {
                        path: 'dodaj',
                        name: 'AddRole',
                        component: AddEditRole,
                    },
                    {
                        path: 'edytuj/:roleId',
                        name: 'EditRole',
                        component: AddEditRole,
                    }
                ]
            },
            {
                path: 'parametry',
                name: 'Parameters',
                component: Parameters,
                children: [
                    {
                        path: 'lista',
                        name: 'ParametersList',
                        component: ParametersList
                    },
                    {
                        path: 'edytuj/:parameterId',
                        name: 'EditParameter',
                        component: EditParameter,
                    }
                ]
            },
            {
                path: "profile",
                name: "profile",
                // lazy-loaded
                component: Profile,
            },
            {
                path: "admin",
                name: "admin",
                // lazy-loaded
                component: BoardAdmin,
            },
            {
                path: "mod",
                name: "moderator",
                // lazy-loaded
                component: BoardModerator,
            },
            {
                path: "user",
                name: "user",
                // lazy-loaded
                component: BoardUser,
            },
            {
                path: "change-password",
                name: "ChangePassword",
                // lazy-loaded
                component: ChangePassword,
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = [`${Global.state.instancePatch}/login`, `${Global.state.instancePatch}/register`, `${Global.state.instancePatch}/home`];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        console.log(Global.state.instancePatch);
        next(`${Global.state.instancePatch}/login`);
    } else {
        next();
    }
});

export default router;
