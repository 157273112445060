import axios from "axios";

const instance = axios.create({
  // 178.33.49.203   test
  // 51.255.122.46  eodpady
  // 178.33.49.203   e-odpady
  //  https://51.255.122.46:3000

  //Dev: https://eodpady.doskomp.pl/api
  //Prod: /api
  baseURL: process.env.NODE_ENV === `development`  ? `https://test-e-odpady.doskomp.pl/api` : `/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
